<template>
  <div style="height: 100%">
    <v-container fluid style="padding: 0; margin: 0">
      <Menu
        style="position:fixed; height: 50px; z-index: 12; background: #dbc7ff"
        :client="true"
        :question="true"
        :nutri_info="true"
      />
      <div style="min-height: calc(100vh - 120px); padding-top: 50px;">
        <v-card color="#F4F9FF" class="mb-3" style="min-height: inherit">
          <v-container
            fluid
            style="height: 100%; margin:0px; padding:0px; min-height: inherit"
            class="d-flex flex-row"
          >
            <client-filter-card
              :onClosePanel="onCloseFilterPanel"
              v-if="filterSection"
              style="top: 120px"
            />
            <div
              class="client-list-body"
              :style="filterSection ? 'margin-left: 20px;' : ''"
            >
              <v-card-title
                class="d-flex flex-row"
                style="justify-content: space-between"
              >
                <label class="item_title">Clients</label>
                <div class="d-flex flex-row">
                  <!-- <button-download-client-data /> -->
                  <v-btn
                    color="#7024C4"
                    dark
                    class="mt-2 ml-3"
                    @click="onDownloadExcel"
                    small
                    outlined
                    :loading="downloadingExcel"
                  >
                    <v-icon class="mr-2">mdi-table-arrow-down</v-icon>
                    Download Excel
                  </v-btn>
                  <v-btn
                    color="#7024C4"
                    dark
                    class="mt-2 ml-3 d-none"
                    @click="confirmDialog = true"
                    small
                  >
                    <v-icon class="mr-2">mdi-database-sync-outline</v-icon>
                    Sync with OneCiti
                  </v-btn>
                  <v-btn
                    color="#7024C4"
                    dark
                    class="mt-2 ml-3"
                    @click="onSendMessage"
                    small
                    outlined
                  >
                    <v-icon class="mr-2" size="16">mdi-message-outline</v-icon>
                    Send Message
                  </v-btn>
                  <v-btn
                    color="#7024C4"
                    dark
                    class="mt-2 ml-3"
                    @click="onQuickCheckedIn"
                    small
                    outlined
                  >
                    <v-icon class="mr-2" size="16"
                      >mdi-check-circle-outline</v-icon
                    >
                    Quick checked in
                  </v-btn>
                  <v-btn
                    color="#7024C4"
                    dark
                    class="mt-2 ml-3"
                    @click="onClientMap"
                    small
                    outlined
                  >
                    <v-icon class="mr-2" size="16">mdi-map</v-icon>
                    Client Map
                  </v-btn>
                  <v-btn
                    color="#7024C4"
                    dark
                    class="mt-2 ml-3"
                    @click="onShowFilter"
                    small
                    :outlined="filterSection"
                  >
                    <v-icon class="mr-2" size="16"
                      >mdi-account-filter-outline</v-icon
                    >
                    Filter
                  </v-btn>
                </div>
              </v-card-title>
              <div
                v-if="
                  familyQuery.length ||
                    checkedInQuery.length ||
                    languages.length ||
                    maritals.length ||
                    english_proficiencies.length ||
                    access_to_foods.length ||
                    meals_challenges.length ||
                    health_rates.length ||
                    access_to_heats.length ||
                    transportations.length ||
                    housings.length ||
                    housing_types.length ||
                    internets.length ||
                    have_transportations.length ||
                    food_insecurities.length ||
                    financial_insecurities.length ||
                    min_day ||
                    max_day ||
                    min_month ||
                    max_month ||
                    min_total_family ||
                    max_total_family ||
                    status ||
                    keyword ||
                    isNew
                "
                class="pa-2"
                style="z-index: 11"
              >
                <div class="d-flex">
                  <span class="mt-2" style="min-width: 90px;">
                    Filtered by:
                  </span>
                  <div
                    class="d-flex align-center filter-chip-section"
                    style="flex-wrap: wrap; width: calc(100% - 90px)"
                  >
                    <v-chip
                      class="ma-2"
                      close
                      @click:close="clearKeyword"
                      v-if="keyword"
                    >
                      {{ keyword }}
                    </v-chip>
                    <v-chip
                      class="ma-2"
                      close
                      @click:close="isNew = false"
                      v-if="isNew"
                    >
                      New
                    </v-chip>
                    <div v-for="language in languages" :key="language">
                      <v-chip
                        class="ma-2"
                        close
                        @click:close="onRemoveLanguage(language)"
                        v-if="languages"
                      >
                        {{
                          language == "Other"
                            ? "Other language"
                            : language == "N/A"
                            ? "Language not set"
                            : language
                        }}
                      </v-chip>
                    </div>
                    <v-chip
                      class="ma-2"
                      close
                      @click:close="onRemoveFamilyTag(index)"
                      v-for="(fm, index) in familyQuery"
                      :key="`family-tag-${index}`"
                    >
                      {{ getFamilyTagLabel(fm) }}
                    </v-chip>
                    <v-chip
                      class="ma-2"
                      close
                      @click:close="onRemoveCheckedInTag(index)"
                      v-for="(fm, index) in checkedInQuery"
                      :key="`checked-in-tag-${index}`"
                    >
                      {{ getCheckedInTagLabel(fm) }}
                    </v-chip>
                    <v-chip
                      class="ma-2"
                      close
                      @click:close="onRemoveDay"
                      v-if="min_day || max_day"
                    >
                      {{ getNeededDay() }}
                    </v-chip>
                    <v-chip
                      class="ma-2"
                      close
                      @click:close="onRemoveMonth"
                      v-if="min_month || max_month"
                    >
                      {{ getNeededMonth() }}
                    </v-chip>
                    <v-chip
                      class="ma-2"
                      close
                      @click:close="onRemoveTotalFamily"
                      v-if="min_total_family || max_total_family"
                    >
                      {{ getNeededTotalFamily() }}
                    </v-chip>
                    <v-chip
                      class="ma-2"
                      close
                      @click:close="onRemoveStatus"
                      v-if="status"
                    >
                      {{ getClientStatus() }}
                    </v-chip>
                    <div v-for="marital in maritals" :key="marital">
                      <v-chip
                        class="ma-2"
                        close
                        @click:close="onRemoveMarital(marital)"
                      >
                        {{ marital }}
                      </v-chip>
                    </div>
                    <div
                      v-for="english in english_proficiencies"
                      :key="`English level: ${english}`"
                    >
                      <v-chip
                        class="ma-2"
                        close
                        @click:close="onRemoveEnglishProficiency(english)"
                      >
                        English level: {{ english }}
                      </v-chip>
                    </div>
                    <div
                      v-for="access_to_food in access_to_foods"
                      :key="access_to_food"
                    >
                      <v-chip
                        class="ma-2"
                        close
                        @click:close="onRemoveAccessToFood(access_to_food)"
                      >
                        {{ access_to_food }}
                      </v-chip>
                    </div>
                    <div
                      v-for="meals_challenge in meals_challenges"
                      :key="meals_challenge"
                    >
                      <v-chip
                        class="ma-2"
                        close
                        @click:close="onRemoveMealsChallenge(meals_challenge)"
                      >
                        {{ meals_challenge }}
                      </v-chip>
                    </div>
                    <div
                      v-for="health_rate in health_rates"
                      :key="`Health rate: ${health_rate}`"
                    >
                      <v-chip
                        class="ma-2"
                        close
                        @click:close="onRemoveMealsRate(health_rate)"
                      >
                        Health rate: {{ health_rate }}
                      </v-chip>
                    </div>
                    <div
                      v-for="access_to_heat in access_to_heats"
                      :key="access_to_heat"
                    >
                      <v-chip
                        class="ma-2"
                        close
                        @click:close="onRemoveAccessHeat(access_to_heat)"
                      >
                        {{ access_to_heat }}
                      </v-chip>
                    </div>
                    <div
                      v-for="transportation in transportations"
                      :key="`Transportation: ${transportation}`"
                    >
                      <v-chip
                        class="ma-2"
                        close
                        @click:close="onRemoveTransportation(transportation)"
                      >
                        Transportation: {{ transportation }}
                      </v-chip>
                    </div>
                    <div v-for="housing in housings" :key="housing">
                      <v-chip
                        class="ma-2"
                        close
                        @click:close="onRemoveHousing(housing)"
                      >
                        {{ housing }}
                      </v-chip>
                    </div>
                    <div
                      v-for="housing_type in housing_types"
                      :key="`Housing type: ${housing_type}`"
                    >
                      <v-chip
                        class="ma-2"
                        close
                        @click:close="onRemoveHousingType(housing_type)"
                      >
                        Housing type: {{ housing_type }}
                      </v-chip>
                    </div>
                    <div
                      v-for="internet in internets"
                      :key="`Internet: ${internet}`"
                    >
                      <v-chip
                        class="ma-2"
                        close
                        @click:close="onRemoveInternet(internet)"
                      >
                        Internet: {{ internet }}
                      </v-chip>
                    </div>
                    <div
                      v-for="have_transportation in have_transportations"
                      :key="`Transportation: ${have_transportation}`"
                    >
                      <v-chip
                        class="ma-2"
                        close
                        @click:close="
                          onRemoveHaveTransportation(have_transportation)
                        "
                      >
                        Have transportation: {{ have_transportation }}
                      </v-chip>
                    </div>
                    <div
                      v-for="food_insecurity in food_insecurities"
                      :key="`Food insecurity: ${food_insecurity}`"
                    >
                      <v-chip
                        class="ma-2"
                        close
                        @click:close="onRemoveFoodInsecurity(food_insecurity)"
                      >
                        Food insecurity: {{ food_insecurity }}
                      </v-chip>
                    </div>
                    <div
                      v-for="financial_insecurity in financial_insecurities"
                      :key="`Financial insecurity: ${financial_insecurity}`"
                    >
                      <v-chip
                        class="ma-2"
                        close
                        @click:close="
                          onRemoveFinancialInsecurity(financial_insecurity)
                        "
                      >
                        Financial insecurity: {{ financial_insecurity }}
                      </v-chip>
                    </div>
                  </div>
                </div>
                <div
                  v-if="summary && (summary.family || summary.people)"
                  class="my-3"
                >
                  <div>
                    <span
                      style="color: #7024c4; font-family: 'Poppins-SemiBold';"
                      >Matched family:</span
                    >
                    <span style="font-weight: 500; margin-left: 5px">
                      {{ summary.family }}
                    </span>
                  </div>
                  <div>
                    <span
                      style="color: #7024c4; font-family: 'Poppins-SemiBold';"
                      >Matched people:</span
                    >
                    <span style="font-weight: 500; margin-left: 5px">
                      {{ summary.people }}
                    </span>
                  </div>
                </div>
              </div>
              <v-data-table
                :headers="headers"
                :items="clients"
                :loading="loading"
                :page.sync="page"
                @click:row="onSelectClient"
                @dblclick:row="onDoubleClicked"
                :item-class="clientsClass"
                :custom-sort="customSort"
                :search="search"
                :custom-filter="customFilter"
                show-expand
                :expanded.sync="expanded"
                item-key="_id"
                class="client-data-table"
                @page-count="pageCount = $event"
              >
                <template v-slot:[`item.image`]="{ item }">
                  <v-avatar class="elevation-6 my-3 ml-5">
                    <v-img :src="item.image ? item.image : placeholderImage" />
                  </v-avatar>
                </template>
                <template v-slot:[`item.info`]="{ item }">
                  <div
                    v-html="
                      getFiltered(
                        (item.first_name ? item.first_name + ' ' : '') +
                          (item.last_name ? item.last_name : '')
                      )
                    "
                    class="mt-3"
                    style="font-size: 14px; color:#482684; font-weight: 500;"
                  />
                  <div
                    v-html="getFiltered(formatPhoneNumber(item.phone))"
                    class="mt-1"
                    style="font-size: 14px; color:#482684"
                  />
                  <div
                    v-html="getFiltered(item.email)"
                    class="mt-1 mb-3"
                    style="font-size: 14px; color:#482684; font-weight: 400;"
                  />
                </template>
                <template v-slot:[`item.client_id`]="{ item }">
                  <div
                    v-html="getFiltered(item.client_id)"
                    style="font-weight: 600; color: #482684"
                  />
                </template>
                <template v-slot:[`item.address`]="{ item }">
                  <div
                    v-html="
                      getFiltered(
                        (item.address ? item.address : 'N/A') +
                          (item.address2 ? ', ' + item.address2 : '')
                      )
                    "
                    style="color: #482684; font-size: 14px;"
                  />
                </template>
                <template v-slot:[`item.rest_balance`]="{ item }">
                  <div class="my-3" style="font-size: 12px;">
                    <div v-if="item.rest_balance && item.rest_balance.fruits">
                      <span
                        class="mr-3"
                        :style="
                          item.status == 'Inactive'
                            ? 'color:lightgray;'
                            : 'color:#400D9B;'
                        "
                        >Fruits:
                      </span>
                      <span
                        v-text="
                          item.rest_balance && item.rest_balance.fruits
                            ? item.rest_balance.fruits
                            : 0
                        "
                        :style="
                          item.status == 'Inactive'
                            ? 'color:lightgray;'
                            : 'color:#7024C4;'
                        "
                      />
                    </div>
                    <div
                      v-if="item.rest_balance && item.rest_balance.vegetables"
                    >
                      <span
                        :style="
                          item.status == 'Inactive'
                            ? 'color:lightgray;'
                            : 'color:#400D9B;'
                        "
                        class="mr-3"
                        >Vegetables:
                      </span>
                      <span
                        v-text="
                          item.rest_balance && item.rest_balance.vegetables
                            ? item.rest_balance.vegetables
                            : 0
                        "
                        :style="
                          item.status == 'Inactive'
                            ? 'color:lightgray;'
                            : 'color:#7024C4;'
                        "
                      />
                    </div>
                    <div v-if="item.rest_balance && item.rest_balance.grains">
                      <span
                        :style="
                          item.status == 'Inactive'
                            ? 'color:lightgray;'
                            : 'color:#400D9B;'
                        "
                        class="mr-3"
                        >Grains:
                      </span>
                      <span
                        v-text="
                          item.rest_balance && item.rest_balance.grains
                            ? item.rest_balance.grains
                            : 0
                        "
                        :style="
                          item.status == 'Inactive'
                            ? 'color:lightgray;'
                            : 'color:#7024C4;'
                        "
                      />
                    </div>
                    <div v-if="item.rest_balance && item.rest_balance.protein">
                      <span
                        :style="
                          item.status == 'Inactive'
                            ? 'color:lightgray;'
                            : 'color:#400D9B;'
                        "
                        class="mr-3"
                        >Protein:
                      </span>
                      <span
                        v-text="
                          item.rest_balance && item.rest_balance.protein
                            ? item.rest_balance.protein
                            : 0
                        "
                        :style="
                          item.status == 'Inactive'
                            ? 'color:lightgray;'
                            : 'color:#7024C4;'
                        "
                      />
                    </div>
                    <div v-if="item.rest_balance && item.rest_balance.dairy">
                      <span
                        :style="
                          item.status == 'Inactive'
                            ? 'color:lightgray;'
                            : 'color:#400D9B;'
                        "
                        class="mr-3"
                        >Dairy:
                      </span>
                      <span
                        v-text="
                          item.rest_balance && item.rest_balance.dairy
                            ? item.rest_balance.dairy
                            : 0
                        "
                        :style="
                          item.status == 'Inactive'
                            ? 'color:lightgray;'
                            : 'color:#7024C4;'
                        "
                      />
                    </div>
                  </div>
                </template>
                <template v-slot:[`item.status`]="{ item }">
                  <div
                    v-html="getFiltered(item.status)"
                    style="font-weight: 400; color: #482684; font-size: 14px;"
                  />
                </template>
                <template v-slot:[`item.expired`]="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        v-html="getExpired(item.intake_date)"
                        v-on="on"
                        v-bind="attrs"
                        style="font-weight: 400; color: #482684; font-size: 14px;"
                      />
                    </template>
                    {{
                      getExpired(item.intake_date) == "Yes"
                        ? "Expired"
                        : "Not expired"
                    }}
                  </v-tooltip>
                </template>
                <template v-slot:[`item.qualified`]="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        v-html="getFiltered(item.qualified ? 'Yes' : 'No')"
                        v-on="on"
                        v-bind="attrs"
                        style="font-weight: 400; color: #482684; font-size: 14px;"
                      />
                    </template>
                    {{ item.qualified ? "Qualified" : "Not qualified" }}
                  </v-tooltip>
                </template>
                <template v-slot:expanded-item="{ item }">
                  <td :colspan="headers.length" class="px-0">
                    <v-data-table
                      :headers="subHeaders"
                      :items="familyData[item.client_id]"
                      hide-default-footer
                      :loading="loadingFamily[item.client_id]"
                      class="px-3"
                    >
                      <template v-slot:[`item.name`]="{ item }">
                        <div
                          v-html="getFiltered(item.name)"
                          :class="filterClass('name', item)"
                        />
                      </template>
                      <template v-slot:[`item.gender`]="{ item }">
                        <div
                          v-html="getFiltered(item.gender)"
                          :class="filterClass('gender', item)"
                        />
                      </template>
                      <template v-slot:[`item.age`]="{ item }">
                        <div>
                          <div
                            v-html="
                              getFiltered(
                                `${
                                  item.age == 0
                                    ? '< 1'
                                    : item.age
                                    ? item.age
                                    : ''
                                }`
                              )
                            "
                            :class="filterClass('age', item)"
                          />
                        </div>
                      </template>
                      <template v-slot:[`item.relation`]="{ item }">
                        <div v-html="getFiltered(item.relation)" />
                      </template>
                      <template v-slot:[`item.situation`]="{ item }">
                        <div
                          v-html="getFiltered(item.situation)"
                          :class="filterClass('situation', item)"
                        />
                      </template>
                      <template v-slot:[`item.schoolName`]="{ item }">
                        <div
                          v-html="getFiltered(item.schoolName)"
                          :class="filterClass('school', item)"
                        />
                      </template>
                    </v-data-table>
                  </td>
                </template>
              </v-data-table>
            </div>
            <client-info-card
              :id="profile._id"
              :onIntake="onIntake"
              :onDeleted="onDeletedClient"
              :onClosePanel="onClosePanel"
              v-if="profile && profile._id"
              style="top: 120px"
            />
          </v-container>
        </v-card>
      </div>
      <v-dialog v-model="confirmDialog" max-width="520px">
        <v-card>
          <v-card-title class="headline justify-center">
            Sync database with OneCiti?
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="confirmDialog = !confirmDialog"
            >
              Cancel
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="confirmSyncAction"
              :loading="loading"
            >
              OK
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-snackbar v-model="snackbar">
        {{ snackMessage }}
      </v-snackbar>
      <filtered-message-dialog
        :dialog="messageDialog"
        :onCloseDialog="onCloseDialog"
        v-if="messageDialog"
      />
      <QuickCheckedInDialog
        :dialog="quickCheckedInDialog"
        :onCloseDialog="onCloseDialog"
        v-if="quickCheckedInDialog"
      />
    </v-container>
  </div>
</template>
<script>
import Menu from "@/components/core/Menu.vue";
import { mapActions, mapGetters } from "vuex";
// import ButtonDownloadClientData from "@/components/client/ButtonDownloadClientData.vue";
import ClientInfoCard from "@/components/client/ClientInfoCard.vue";
import ClientFilterCard from "@/components/client/ClientFilterCard.vue";
import FilteredMessageDialog from "@/components/client/FilteredMessageDialog.vue";
import QuickCheckedInDialog from "../../components/client/QuickCheckedInDialog.vue";
import writeXlsxFile from "write-excel-file";

export default {
  components: {
    Menu,
    // ButtonDownloadClientData,
    ClientInfoCard,
    ClientFilterCard,
    FilteredMessageDialog,
    QuickCheckedInDialog,
  },
  data() {
    return {
      isSelectingPhoto: false,
      loading: false,
      snackbar: false,
      errorMessage: null,
      snackMessage: null,
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => (v && v.length >= 8) || "Min 8 characters",
        passwordMatch: () =>
          this.profile.password != this.profile.confirmPassword
            ? `Password doesn't match`
            : "",
      },
      showPassword: false,
      headers: [
        { text: "   ", value: "image", sortable: false },
        { text: "ClientId", value: "client_id" },
        { text: "Info", value: "info" },
        { text: "Address", value: "address" },
        { text: "Eligible", value: "rest_balance" },
        // { text: "Status", value: "status" },
        { text: "Qualified", value: "qualified" },
        { text: "Expired", value: "expired" },
        { text: "", value: "data-table-expand" },
      ],
      subHeaders: [
        { text: "Name", value: "name" },
        { text: "Gender", value: "gender" },
        { text: "Age", value: "age" },
        { text: "Relation", value: "relation" },
        { text: "Situation", value: "situation" },
        { text: "School Name", value: "schoolName" },
      ],
      selected: -1,
      placeholderImage: require("@/assets/default.jpg"),
      profile: { email: "" },
      selectedFile: null,
      deleteDialog: false,
      confirmDialog: false,
      statusOptions: [
        "No",
        "Yes",
        "Urgent Help",
        "Intake Completed",
        "No response",
        "Active",
        "Inactive",
      ],
      search: "",
      clients: [],
      expanded: [],
      familyData: {},
      loadingFamily: [],
      filteredFamily: {},
      countData: {},
      summary: {},
      filterSection: false,
      filterOptions: {},
      genderOptions: ["Male", "Female", ""],
      languageOptions: ["English", "Portuguese", "Spanish", "Russian", "Other"],
      min_day: "",
      max_day: "",
      min_month: "",
      max_month: "",
      min_total_family: "",
      max_total_family: "",
      status: "",
      gender: "",
      languages: [],
      relations: [],
      situations: [],
      maritals: [],
      english_proficiencies: [],
      access_to_foods: [],
      meals_challenges: [],
      health_rates: [],
      access_to_heats: [],
      transportations: [],
      housings: [],
      housing_types: [],
      schools: [],
      internets: [],
      have_transportations: [],
      food_insecurities: [],
      financial_insecurities: [],
      familyQuery: [],
      checkedInQuery: [],
      isNew: false,
      offsetTop: 0,
      messageDialog: false,
      quickCheckedInDialog: false,
      downloadingExcel: false,
      page: 2,
      pageCount: 0,
    };
  },
  computed: {
    ...mapGetters({
      allClients: "client/getAllClients",
      keyword: "auth/getKeyword",
    }),
  },
  methods: {
    ...mapActions({
      fetchAllClients: "client/fetchAllClients",
      syncWithOneCiti: "client/syncWithOneCiti",
      editClient: "client/editClient",
      deleteClient: "client/deleteClient",
      setBalance: "client/setBalance",
      clearKeyword: "auth/clearKeyword",
      fetchFamilyIntakes: "intake/fetchFamilyIntakes",
      getFamilyFiltered: "intake/getFamilyFiltered",
      getValues: "variable/getValues",
      getExcelJsonData: "client/getExcelJsonData",
    }),
    onImageSelected(file) {
      this.selectedFile = file;
    },
    onChange() {
      this.errorMessage = null;
    },
    onSelectClient(item) {
      this.selected = this.clients.indexOf(item);
    },
    onCloseDialog() {
      this.messageDialog = false;
      this.quickCheckedInDialog = false;
    },
    getAddressData(addressData) {
      this.profile.address = addressData.name;
      this.profile.state = addressData.administrative_area_level_1;
      this.profile.city = addressData.locality;
      this.profile.zip = addressData.postal_code;
      this.profile.location =
        addressData.latitude + "," + addressData.longitude;
      this.$refs.address.update(this.profile.address);
    },
    onIntake() {
      this.$router.push({ name: "intake", params: { id: this.profile._id } });
    },
    onDoubleClicked(event, { item }) {
      this.selected = this.clients.indexOf(item);
      this.$router.push({ name: "intake", params: { id: this.profile._id } });
    },
    showErrorMessage(message) {
      this.snackMessage = message;
      this.snackbar = true;
    },
    clientsClass(item) {
      if (this.profile && item._id == this.profile._id) {
        return "selected";
      }
      return "";
    },
    confirmSyncAction() {
      this.confirmDialog = false;
      this.loading = true;
      this.syncWithOneCiti()
        .then(() => {
          this.loading = false;
          this.snackbar = true;
          this.snackMessage = "Sync database is done successfully";
        })
        .catch((error) => {
          this.loading = false;
          if (error.response != undefined && error.response.status === 401) {
            this.tokenDialog = true;
          } else {
            console.log(error);
          }
        });
    },
    formatPhoneNumber(str) {
      let cleaned = ("" + str).replace(/\D/g, "");
      let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        let intlCode = match[1] ? "+1 " : "";
        return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join(
          ""
        );
      }
      return str;
    },
    customSort(items, index, isDesc) {
      if (index.length == 0 || index[0] == "address") {
        items.sort((a, b) => {
          if (!a.address && !b.address) return 0;
          if (!a.address) return 1;
          if (!b.address) return -1;
          var aFirstIntStr = a.address
            ? a.address.replace(/(^\d+)(.+$)/i, "$1")
            : "";
          var bFirstIntStr = b.address
            ? b.address.replace(/(^\d+)(.+$)/i, "$1")
            : "";
          var aInt = 0;
          var bInt = 0;
          if (aFirstIntStr) {
            aInt = parseInt(aFirstIntStr);
            if (isNaN(aInt)) aInt = 0;
          }
          if (bFirstIntStr) {
            bInt = parseInt(bFirstIntStr);
            if (isNaN(bInt)) bInt = 0;
          }
          if (aInt != bInt) return aInt - bInt;
          var aAddress = `${a.address ? a.address : ""}${
            a.address && a.address2 ? ", " : ""
          }${a.address2 ? a.address2 : ""}`;
          var bAddress = `${b.address ? b.address : ""}${
            b.address && b.address2 ? ", " : ""
          }${b.address2 ? b.address2 : ""}`;
          if (aAddress < bAddress) return -1;
          if (aAddress > bAddress) return 1;
          return 0;
        });
      } else if (index[0] === "info") {
        items.sort((a, b) => {
          var order = 0;
          var aStr = `${a["first_name"]} ${a["last_name"]}`;
          var bStr = `${b["first_name"]} ${b["last_name"]}`;

          if (aStr < bStr) order = -1;
          else if (aStr > bStr) order = 1;
          if (isDesc[0]) order *= -1;
          return order;
        });
      } else if (index[0] === "expired") {
        items.sort((a, b) => {
          var order = 0;
          var aDate = this.getExpired(a.intake_date);
          var bDate = this.getExpired(b.intake_date);
          if (!aDate && !bDate) return order;
          if (aDate == bDate) return order;
          if (!aDate && bDate == "No") return -1;
          if (!aDate && bDate == "Yes") return 1;
          if (!bDate && aDate == "No") return 1;
          if (!bDate && aDate == "Yes") return -1;

          if (aDate == "Yes" && bDate == "No") order = -1;
          else order = 1;
          return order;
        });
      } else {
        items.sort((a, b) => {
          var order = 0;
          if (a[index] < b[index]) order = -1;
          else if (a[index] > b[index]) order = 1;
          if (isDesc[0]) order *= -1;
          return order;
        });
      }
      return items;
    },
    getFiltered(words) {
      if (!words) return "";
      var filtered = this.$options.filters.highlight(words, this.keyword);
      return filtered;
    },
    customFilter(value, search, item) {
      var searchFilter = false;
      const key = this.onGetKey();
      if (
        this.keyword &&
        !this.min_age &&
        !this.max_age &&
        !this.gender &&
        !this.relations.length &&
        !this.situations.length &&
        !this.schools.length &&
        !this.internets.length &&
        !this.have_transportations.length &&
        !this.food_insecurities.length &&
        !this.financial_insecurities.length
      ) {
        if (
          (`${item.first_name} ${item.last_name}` &&
            `${item.first_name} ${item.last_name}`
              .toUpperCase()
              .includes(this.keyword.toUpperCase())) ||
          (item.phone &&
            item.phone.toUpperCase().includes(this.keyword.toUpperCase())) ||
          (item.phone &&
            this.formatPhoneNumber(item.phone)
              .toUpperCase()
              .includes(this.keyword.toUpperCase())) ||
          (item.address &&
            item.address.toUpperCase().includes(this.keyword.toUpperCase())) ||
          (item.address2 &&
            item.address2.toUpperCase().includes(this.keyword.toUpperCase())) ||
          (item.address &&
            item.address2 &&
            `${item.address}, ${item.address2}`
              .toUpperCase()
              .includes(this.keyword.toUpperCase())) ||
          // (item.status &&
          //   item.status.toUpperCase().includes(this.keyword.toUpperCase())) ||
          (item.email &&
            item.email.toUpperCase().includes(this.keyword.toUpperCase())) ||
          (item.client_id &&
            `${item.client_id}`
              .toUpperCase()
              .includes(this.keyword.toUpperCase()))
        ) {
          return true;
        }
      } else if (key == "{}") {
        return true;
      }

      if (
        this.filteredFamily[key] != undefined &&
        this.filteredFamily[key].length != 0
      ) {
        if (this.filteredFamily[key].includes(item._id)) {
          return true;
        } else return false;
      }
      return searchFilter;
    },
    onDeletedClient() {
      this.clearKeyword();
      if (this.selected) this.selected--;
    },
    getExpired(dateStr) {
      if (!dateStr) return "";
      var today = new Date();
      var date = new Date(dateStr);
      const diffTime = Math.abs(today - date);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      if (diffDays >= 90) return "Yes";
      else return "No";
    },
    getFamilyData(client_id) {
      this.loadingFamily[client_id] = true;
      this.fetchFamilyIntakes({ client_id })
        .then((res) => {
          this.loadingFamily[client_id] = false;
          this.familyData[client_id] = res;
          this.familyData = { ...this.familyData };
        })
        .catch((error) => {
          this.loadingFamily[client_id] = false;
          console.log(error.response.data.message);
        });
    },
    onClosePanel() {
      this.profile = {};
      this.selected = -1;
    },
    onCloseFilterPanel() {
      this.filterSection = !this.filterSection;
    },
    onShowFilter() {
      this.filterSection = !this.filterSection;
    },
    onChangeFilter() {
      this.filterOptions = {
        ...this.filterOptions,
        language: this.languages.join(),
        marital: this.maritals.join(),
        english_proficiency: this.english_proficiencies.join(),
        access_to_food: this.access_to_foods.join(),
        meals_challenge: this.meals_challenges.join(),
        health_rate: this.health_rates.join(),
        access_to_heat: this.access_to_heats.join(),
        transportation: this.transportations.join(),
        housing: this.housings.join(),
        housing_type: this.housing_types.join(),
        internet: this.internets.join(),
        have_transportation: this.have_transportations.join(),
        food_insecurity: this.food_insecurities.join(),
        financial_insecurity: this.financial_insecurities.join(),
        min_day: this.min_day,
        max_day: this.max_day,
        min_month: this.min_month,
        max_month: this.max_month,
        min_total_family: this.min_total_family,
        max_total_family: this.max_total_family,
        status: this.status,
        family: JSON.stringify({ query: this.familyQuery }),
        checked_in: JSON.stringify({ query: this.checkedInQuery }),
      };
      this.onSearchFamily();
    },
    onRemoveGender() {
      this.gender = null;
      this.onChangeFilter();
    },
    onRemoveLanguage(language) {
      this.languages = this.languages.filter((l) => l != language);
      this.onChangeFilter();
    },
    onRemoveDay() {
      this.min_day = "";
      this.max_day = "";
      this.onChangeFilter();
    },
    onRemoveMonth() {
      this.min_month = "";
      this.max_month = "";
      this.onChangeFilter();
    },
    onRemoveTotalFamily() {
      this.min_total_family = "";
      this.max_total_family = "";
      this.onChangeFilter();
    },
    onRemoveStatus() {
      this.status = "";
      this.onChangeFilter();
    },
    onRemoveFamilyTag(index) {
      console.log(this.familyQuery, index);
      if (this.familyQuery.length > index) {
        this.familyQuery.splice(index, 1);
      }
      console.log(this.familyQuery);
      this.onChangeFilter();
    },
    onRemoveCheckedInTag(index) {
      console.log(this.checkedInQuery, index);
      if (this.checkedInQuery.length > index) {
        this.checkedInQuery.splice(index, 1);
      }
      console.log(this.checkedInQuery);
      this.onChangeFilter();
    },
    onRemoveMarital(marital) {
      this.maritals = this.maritals.filter((s) => s != marital);
      this.onChangeFilter();
    },
    onRemoveEnglishProficiency(english_proficiency) {
      this.english_proficiencies = this.english_proficiencies.filter(
        (s) => s != english_proficiency
      );
      this.onChangeFilter();
    },
    onRemoveAccessToFood(access_to_food) {
      this.access_to_foods = this.access_to_foods.filter(
        (s) => s != access_to_food
      );
      this.onChangeFilter();
    },
    onRemoveMealsChallenge(meals_challenge) {
      this.meals_challenges = this.meals_challenges.filter(
        (s) => s != meals_challenge
      );
      this.onChangeFilter();
    },
    onRemoveMealsRate(health_rate) {
      this.health_rates = this.health_rates.filter((s) => s != health_rate);
      this.onChangeFilter();
    },
    onRemoveAccessHeat(access_to_heat) {
      this.access_to_heats = this.access_to_heats.filter(
        (s) => s != access_to_heat
      );
      this.onChangeFilter();
    },
    onRemoveTransportation(transportation) {
      this.transportations = this.transportations.filter(
        (s) => s != transportation
      );
      this.onChangeFilter();
    },
    onRemoveHousing(housing) {
      this.housings = this.housings.filter((s) => s != housing);
      this.onChangeFilter();
    },
    onRemoveHousingType(housing) {
      this.housing_types = this.housing_types.filter((s) => s != housing);
      this.onChangeFilter();
    },
    onRemoveInternet(internet) {
      this.internets = this.internets.filter((s) => s != internet);
      this.onChangeFilter();
    },
    onRemoveHaveTransportation(have_transportation) {
      this.have_transportations = this.have_transportations.filter(
        (s) => s != have_transportation
      );
      this.onChangeFilter();
    },
    onRemoveFoodInsecurity(food_insecurity) {
      this.food_insecurities = this.food_insecurities.filter(
        (s) => s != food_insecurity
      );
      this.onChangeFilter();
    },
    onRemoveFinancialInsecurity(financial_insecurity) {
      this.financial_insecurities = this.financial_insecurities.filter(
        (s) => s != financial_insecurity
      );
      this.onChangeFilter();
    },
    onUpdateSummary() {
      var key = this.onGetKey();
      this.summary = this.countData[key];
    },
    /**
     * Search family data with filter and update data to prevent same search again
     */
    onSearchFamily() {
      var key = this.onGetKey();
      this.search = key;
      if (key == "{}") {
        this.onUpdateSummary();
        return;
      }
      var params = {
        ...JSON.parse(key),
      };
      if (!this.filteredFamily[key]) {
        this.loading = true;
        this.getFamilyFiltered(params)
          .then((res) => {
            this.loading = false;
            var sum = 0;
            this.filteredFamily[key] = res.map((r) => {
              return r._id;
            });
            for (var i = 0; i < res.length; i++) {
              sum += res[i].count;
            }
            this.countData[key] = { family: res.length, people: sum };
            this.onUpdateSummary();
            this.search = "update" + key;
          })
          .catch((error) => {
            this.loading = false;
            if (error.response) {
              console.log(error.response.data.message);
            } else {
              console.log(error);
            }
          });
      } else {
        this.summary = this.countData[key];
      }
    },
    getFamilyTagLabel(tag) {
      let memberTag = "";
      if (tag.name) {
        memberTag += memberTag ? ", " : "";
        memberTag += tag.name;
      }
      if (tag.min_age || tag.max_age) {
        memberTag += memberTag ? ", " : "";
        if (tag.min_age && tag.max_age) {
          memberTag += `Age ${tag.min_age}-${tag.max_age}`;
        } else if (tag.min_age) {
          memberTag += `Age >${tag.min_age}`;
        } else {
          memberTag += `Age <${tag.max_age}`;
        }
      }
      if (tag.gender && tag.gender != "Both") {
        memberTag += memberTag ? ", " : "";
        memberTag += tag.gender;
      }
      if (tag.relation) {
        let relations = tag.relation.split(",").sort();
        memberTag += memberTag ? ", " : "";
        memberTag += relations.join(", ");
      }
      if (tag.situation) {
        let situations = tag.situation.split(",").sort();
        memberTag += memberTag ? ", " : "";
        memberTag += situations.join(", ");
      }
      if (tag.school) {
        let schools = tag.school.split(",").sort();
        memberTag += memberTag ? ", " : "";
        memberTag += schools.join(", ");
      }
      if (tag.is_pregnant) {
        memberTag += memberTag ? ", " : "";
        memberTag += "Is pregnant or nursing";
      }
      if (tag.min_height || tag.max_height) {
        memberTag += memberTag ? ", " : "";
        if (tag.min_height && tag.max_height) {
          memberTag += `Height ${tag.min_height}-${tag.max_height}`;
        } else if (tag.min_height) {
          memberTag += `Height >${tag.min_height}`;
        } else {
          memberTag += `Height <${tag.max_height}`;
        }
      }
      if (tag.min_weight || tag.max_weight) {
        memberTag += memberTag ? ", " : "";
        if (tag.min_weight && tag.max_weight) {
          memberTag += `Weight ${tag.min_weight}-${tag.max_weight}`;
        } else if (tag.min_weight) {
          memberTag += `Weight >${tag.min_weight}`;
        } else {
          memberTag += `Weight <${tag.max_weight}`;
        }
      }
      if (tag.min_bmi || tag.max_bmi) {
        memberTag += memberTag ? ", " : "";
        if (tag.min_bmi && tag.max_bmi) {
          memberTag += `BMI ${tag.min_bmi}-${tag.max_bmi}`;
        } else if (tag.min_bmi) {
          memberTag += `BMI >${tag.min_bmi}`;
        } else {
          memberTag += `BMI <${tag.max_bmi}`;
        }
      }
      if (tag.min_total_cholesterol || tag.max_total_cholesterol) {
        memberTag += memberTag ? ", " : "";
        if (tag.min_total_cholesterol && tag.max_total_cholesterol) {
          memberTag += `Total Cholesterol ${tag.min_total_cholesterol}-${tag.max_total_cholesterol}`;
        } else if (tag.min_total_cholesterol) {
          memberTag += `Total Cholesterol >${tag.min_total_cholesterol}`;
        } else {
          memberTag += `Total Cholesterol <${tag.max_total_cholesterol}`;
        }
      }
      if (tag.min_hdl || tag.max_hdl) {
        memberTag += memberTag ? ", " : "";
        if (tag.min_hdl && tag.max_hdl) {
          memberTag += `HDL ${tag.min_hdl}-${tag.max_hdl}`;
        } else if (tag.min_hdl) {
          memberTag += `HDL >${tag.min_hdl}`;
        } else {
          memberTag += `HDL <${tag.max_hdl}`;
        }
      }
      if (tag.min_systolic || tag.max_systolic) {
        memberTag += memberTag ? ", " : "";
        if (tag.min_systolic && tag.max_systolic) {
          memberTag += `Systolic ${tag.min_systolic}-${tag.max_systolic}`;
        } else if (tag.min_systolic) {
          memberTag += `Systolic >${tag.min_systolic}`;
        } else {
          memberTag += `Systolic <${tag.max_systolic}`;
        }
      }
      if (tag.min_diastolic || tag.max_diastolic) {
        memberTag += memberTag ? ", " : "";
        if (tag.min_diastolic && tag.max_diastolic) {
          memberTag += `Diastolic ${tag.min_diastolic}-${tag.max_diastolic}`;
        } else if (tag.min_diastolic) {
          memberTag += `Diastolic >${tag.min_diastolic}`;
        } else {
          memberTag += `Diastolic <${tag.max_diastolic}`;
        }
      }
      if (tag.min_blood_sugar || tag.max_blood_sugar) {
        memberTag += memberTag ? ", " : "";
        if (tag.min_blood_sugar && tag.max_blood_sugar) {
          memberTag += `Blood Sugar ${tag.min_blood_sugar}-${tag.max_blood_sugar}`;
        } else if (tag.min_blood_sugar) {
          memberTag += `Blood Sugar >${tag.min_blood_sugar}`;
        } else {
          memberTag += `Blood Sugar <${tag.max_blood_sugar}`;
        }
      }
      return memberTag;
    },
    getCheckedInTagLabel(tag) {
      let memberTag = "";
      if (tag.checked_in_min_age || tag.checked_in_max_age) {
        memberTag += memberTag ? ", " : "";
        if (tag.checked_in_min_age && tag.checked_in_max_age) {
          memberTag += `Checked In Age ${tag.checked_in_min_age}-${tag.checked_in_max_age}`;
        } else if (tag.checked_in_min_age) {
          memberTag += `Checked In Age >${tag.checked_in_min_age}`;
        } else {
          memberTag += `Checked In Age <${tag.checked_in_max_age}`;
        }
      }
      if (
        tag.checked_in_min_family_number ||
        tag.checked_in_max_family_number
      ) {
        memberTag += memberTag ? ", " : "";
        if (
          tag.checked_in_min_family_number &&
          tag.checked_in_max_family_number
        ) {
          memberTag += `Checked In Visitors: ${tag.checked_in_min_family_number}-${tag.checked_in_max_family_number}`;
        } else if (tag.checked_in_min_family_number) {
          memberTag += `Checked In Visitors >${tag.checked_in_min_family_number}`;
        } else {
          memberTag += `Checked In Visitors <${tag.checked_in_max_family_number}`;
        }
      }
      if (tag.checked_in_from_date || tag.checked_in_to_date) {
        memberTag += memberTag ? ", " : "";
        if (tag.checked_in_from_date && tag.checked_in_to_date) {
          memberTag += `Checked In From ${tag.checked_in_from_date} To ${tag.checked_in_to_date}`;
        } else if (tag.checked_in_from_date) {
          memberTag += `Checked In From ${tag.checked_in_from_date}`;
        } else {
          memberTag += `Checked In To ${tag.checked_in_to_date}`;
        }
      }
      if (tag.checked_in_name) {
        memberTag += memberTag ? ", " : "";
        memberTag += "Visitor Name: " + tag.checked_in_name;
      }
      if (tag.checked_in_gender && tag.checked_in_gender != "Both") {
        memberTag += memberTag ? ", " : "";
        memberTag += "Checked in gender: " + tag.checked_in_gender;
      }
      if (tag.checked_in_relation) {
        let relations = tag.checked_in_relation.split(",").sort();
        memberTag += memberTag ? ", " : "";
        memberTag += "Checked in relations: " + relations.join(", ");
      }
      if (tag.checked_in_situation) {
        let situations = tag.checked_in_situation.split(",").sort();
        memberTag += memberTag ? ", " : "";
        memberTag += "Checked in situations: " + situations.join(", ");
      }
      if (tag.checked_in_school) {
        let schools = tag.checked_in_school.split(",").sort();
        memberTag += memberTag ? ", " : "";
        memberTag += "Checked in schools: " + schools.join(", ");
      }

      // if (tag.is_pregnant) {
      //   memberTag += memberTag ? ", " : "";
      //   memberTag += "Is pregnant or nursing";
      // }
      // if (tag.min_height || tag.max_height) {
      //   memberTag += memberTag ? ", " : "";
      //   if (tag.min_height && tag.max_height) {
      //     memberTag += `Height ${tag.min_height}-${tag.max_height}`;
      //   } else if (tag.min_height) {
      //     memberTag += `Height >${tag.min_height}`;
      //   } else {
      //     memberTag += `Height <${tag.max_height}`;
      //   }
      // }
      // if (tag.min_weight || tag.max_weight) {
      //   memberTag += memberTag ? ", " : "";
      //   if (tag.min_weight && tag.max_weight) {
      //     memberTag += `Weight ${tag.min_weight}-${tag.max_weight}`;
      //   } else if (tag.min_weight) {
      //     memberTag += `Weight >${tag.min_weight}`;
      //   } else {
      //     memberTag += `Weight <${tag.max_weight}`;
      //   }
      // }
      // if (tag.min_bmi || tag.max_bmi) {
      //   memberTag += memberTag ? ", " : "";
      //   if (tag.min_bmi && tag.max_bmi) {
      //     memberTag += `BMI ${tag.min_bmi}-${tag.max_bmi}`;
      //   } else if (tag.min_bmi) {
      //     memberTag += `BMI >${tag.min_bmi}`;
      //   } else {
      //     memberTag += `BMI <${tag.max_bmi}`;
      //   }
      // }
      // if (tag.min_total_cholesterol || tag.max_total_cholesterol) {
      //   memberTag += memberTag ? ", " : "";
      //   if (tag.min_total_cholesterol && tag.max_total_cholesterol) {
      //     memberTag += `Total Cholesterol ${tag.min_total_cholesterol}-${tag.max_total_cholesterol}`;
      //   } else if (tag.min_total_cholesterol) {
      //     memberTag += `Total Cholesterol >${tag.min_total_cholesterol}`;
      //   } else {
      //     memberTag += `Total Cholesterol <${tag.max_total_cholesterol}`;
      //   }
      // }
      // if (tag.min_hdl || tag.max_hdl) {
      //   memberTag += memberTag ? ", " : "";
      //   if (tag.min_hdl && tag.max_hdl) {
      //     memberTag += `HDL ${tag.min_hdl}-${tag.max_hdl}`;
      //   } else if (tag.min_hdl) {
      //     memberTag += `HDL >${tag.min_hdl}`;
      //   } else {
      //     memberTag += `HDL <${tag.max_hdl}`;
      //   }
      // }
      // if (tag.min_systolic || tag.max_systolic) {
      //   memberTag += memberTag ? ", " : "";
      //   if (tag.min_systolic && tag.max_systolic) {
      //     memberTag += `Systolic ${tag.min_systolic}-${tag.max_systolic}`;
      //   } else if (tag.min_systolic) {
      //     memberTag += `Systolic >${tag.min_systolic}`;
      //   } else {
      //     memberTag += `Systolic <${tag.max_systolic}`;
      //   }
      // }
      // if (tag.min_diastolic || tag.max_diastolic) {
      //   memberTag += memberTag ? ", " : "";
      //   if (tag.min_diastolic && tag.max_diastolic) {
      //     memberTag += `Diastolic ${tag.min_diastolic}-${tag.max_diastolic}`;
      //   } else if (tag.min_diastolic) {
      //     memberTag += `Diastolic >${tag.min_diastolic}`;
      //   } else {
      //     memberTag += `Diastolic <${tag.max_diastolic}`;
      //   }
      // }
      // if (tag.min_blood_sugar || tag.max_blood_sugar) {
      //   memberTag += memberTag ? ", " : "";
      //   if (tag.min_blood_sugar && tag.max_blood_sugar) {
      //     memberTag += `Blood Sugar ${tag.min_blood_sugar}-${tag.max_blood_sugar}`;
      //   } else if (tag.min_blood_sugar) {
      //     memberTag += `Blood Sugar >${tag.min_blood_sugar}`;
      //   } else {
      //     memberTag += `Blood Sugar <${tag.max_blood_sugar}`;
      //   }
      // }
      return memberTag;
    },
    getNeededDay() {
      if (this.min_day && this.max_day) {
        if (this.min_day == this.max_day) return `Needed day = ${this.min_day}`;
        return `${this.min_day}<Needed day<${this.max_day}`;
      }
      if (this.min_day) {
        return `Needed day > ${this.min_day}`;
      } else if (this.max_day) {
        return `Needed day < ${this.max_day}`;
      }
      return "";
    },
    getNeededMonth() {
      if (this.min_month && this.max_month) {
        if (this.min_month == this.max_month)
          return `Needed month = ${this.min_month}`;
        return `${this.min_month}<Needed month<${this.max_month}`;
      }
      if (this.min_month) {
        return `Needed month > ${this.min_month}`;
      } else if (this.max_month) {
        return `Needed month < ${this.max_month}`;
      }
      return "";
    },
    getNeededTotalFamily() {
      if (this.min_total_family && this.max_total_family) {
        if (this.min_total_family == this.max_total_family)
          return `Number of people = ${this.min_total_family}`;
        return `${this.min_total_family}<Number of people<${this.max_total_family}`;
      }
      if (this.min_total_family) {
        return `Number of people > ${this.min_total_family}`;
      } else if (this.max_total_family) {
        return `Number of people < ${this.max_total_family}`;
      }
      return "";
    },
    getClientStatus() {
      if (this.status) {
        return `Status: ${this.status}`;
      }
      return "";
    },
    onGetKey() {
      var obj = {};
      if (this.keyword) obj.key = this.keyword;
      if (this.languages && this.languages.length) {
        this.languages = this.languages.sort();
        obj.language = this.languages.join();
      }
      if (this.maritals && this.maritals.length) {
        this.maritals = this.maritals.sort();
        obj.marital = this.maritals.join();
      }
      if (this.english_proficiencies && this.english_proficiencies.length) {
        this.english_proficiencies = this.english_proficiencies.sort();
        obj.english_proficiency = this.english_proficiencies.join();
      }
      if (this.access_to_foods && this.access_to_foods.length) {
        this.access_to_foods = this.access_to_foods.sort();
        obj.access_to_food = this.access_to_foods.join();
      }
      if (this.meals_challenges && this.meals_challenges.length) {
        this.meals_challenges = this.meals_challenges.sort();
        obj.meals_challenge = this.meals_challenges.join();
      }
      if (this.health_rates && this.health_rates.length) {
        this.health_rates = this.health_rates.sort();
        obj.health_rate = this.health_rates.join();
      }
      if (this.access_to_heats && this.access_to_heats.length) {
        this.access_to_heats = this.access_to_heats.sort();
        obj.access_to_heat = this.access_to_heats.join();
      }
      if (this.transportations && this.transportations.length) {
        this.transportations = this.transportations.sort();
        obj.transportation = this.transportations.join();
      }
      if (this.housings && this.housings.length) {
        this.housings = this.housings.sort();
        obj.housing = this.housings.join();
      }
      if (this.housing_types && this.housing_types.length) {
        this.housing_types = this.housing_types.sort();
        obj.housing_type = this.housing_types.join();
      }
      if (this.internets && this.internets.length) {
        this.internets = this.internets.sort();
        obj.internet = this.internets.join();
      }
      if (this.have_transportations && this.have_transportations.length) {
        this.have_transportations = this.have_transportations.sort();
        obj.have_transportation = this.have_transportations.join();
      }
      if (this.food_insecurities && this.food_insecurities.length) {
        this.food_insecurities = this.food_insecurities.sort();
        obj.food_insecurity = this.food_insecurities.join();
      }
      if (this.financial_insecurities && this.financial_insecurities.length) {
        this.financial_insecurities = this.financial_insecurities.sort();
        obj.financial_insecurity = this.financial_insecurities.join();
      }
      if (this.min_day) obj.min_day = this.min_day;
      if (this.max_day) obj.max_day = this.max_day;
      if (this.min_month) obj.min_month = this.min_month;
      if (this.max_month) obj.max_month = this.max_month;
      if (this.min_total_family) obj.min_total_family = this.min_total_family;
      if (this.max_total_family) obj.max_total_family = this.max_total_family;
      if (this.status) obj.status = this.status;

      if (this.isNew) obj.is_new = true;
      if (this.familyQuery) obj.family_query = this.familyQuery;
      if (this.checkedInQuery) obj.checked_in_query = this.checkedInQuery;
      return JSON.stringify(obj);
    },
    filterClass(key, item) {
      const value = item[key];
      if (value == undefined) return "";
      var res = "";

      if (this.min_age && this.max_age) {
        if (
          this.min_age <= parseInt(`${item.age}`) &&
          this.max_age >= parseInt(`${item.age}`)
        ) {
          if (key == "age") res = "filteredClass";
        } else return "";
      } else if (this.min_age) {
        if (this.min_age <= parseInt(`${item.age}`)) {
          if (key == "age") res = "filteredClass";
        } else return "";
      } else if (this.max_age) {
        if (this.max_age >= parseInt(`${item.age}`)) {
          if (key == "age") res = "filteredClass";
        } else return "";
      }

      if (this.gender && this.gender != "Both") {
        if (this.gender == item.gender) {
          if (key == "gender") res = "filteredClass";
        } else return "";
      }

      if (this.relations && this.relations.length) {
        if (this.relations.includes(item.relation)) {
          if (key == "relation") res = "filteredClass";
        } else return "";
      }

      if (this.situations && this.situations.length) {
        if (this.situations.includes(item.situation)) {
          if (key == "situation") res = "filteredClass";
        } else return "";
      }
      if (this.schools && this.schools.length) {
        if (this.schools.includes(item.school)) {
          if (key == "schools") res = "filteredClass";
        } else return "";
      }

      return res;
    },
    onScroll() {
      this.offsetTop = window.top.scrollY;
    },
    filterUpdateWithRoute() {
      this.filterOptions = { ...this.$route.query };
      // this.max_age = this.filterOptions.max_age;
      // this.min_age = this.filterOptions.min_age;
      // this.gender = this.filterOptions.gender;
      this.isNew =
        this.filterOptions.new == "true" || this.filterOptions.new == true;
      if (this.filterOptions.language)
        this.languages = this.filterOptions.language.split(",");
      else this.languages = [];
      // if (this.filterOptions.situation)
      //   this.situations = this.filterOptions.situation.split(",");
      if (this.filterOptions.marital)
        this.maritals = this.filterOptions.marital.split(",");
      else this.maritals = [];
      if (this.filterOptions.english_proficiency)
        this.english_proficiencies = this.filterOptions.english_proficiency.split(
          ","
        );
      else this.english_proficiencies = [];
      if (this.filterOptions.access_to_food)
        this.access_to_foods = this.filterOptions.access_to_food.split(",");
      else this.access_to_foods = [];
      if (this.filterOptions.meals_challenge)
        this.meals_challenges = this.filterOptions.meals_challenge.split(",");
      else this.meals_challenges = [];
      if (this.filterOptions.health_rate)
        this.health_rates = this.filterOptions.health_rate.split(",");
      else this.health_rates = [];
      if (this.filterOptions.access_to_heat)
        this.access_to_heats = this.filterOptions.access_to_heat.split(",");
      else this.access_to_heats = [];
      if (this.filterOptions.transportation)
        this.transportations = this.filterOptions.transportation.split(",");
      else this.transportations = [];
      if (this.filterOptions.housing)
        this.housings = this.filterOptions.housing.split(",");
      else this.housings = [];
      if (this.filterOptions.housing_type)
        this.housing_types = this.filterOptions.housing_type.split(",");
      else this.housing_types = [];
      if (this.filterOptions.internet)
        this.internets = this.filterOptions.internet.split(",");
      else this.internets = [];
      if (this.filterOptions.have_transportation)
        this.have_transportations = this.filterOptions.have_transportation.split(
          ","
        );
      else this.have_transportations = [];

      if (this.filterOptions.food_insecurity)
        this.food_insecurities = this.filterOptions.food_insecurity.split(",");
      else this.food_insecurities = [];

      if (this.filterOptions.financial_insecurity)
        this.financial_insecurities = this.filterOptions.financial_insecurity.split(
          ","
        );
      else this.financial_insecurities = [];

      this.min_day = this.filterOptions.min_day;
      this.max_day = this.filterOptions.max_day;
      this.min_month = this.filterOptions.min_month;
      this.max_month = this.filterOptions.max_month;
      this.min_total_family = this.filterOptions.min_total_family;
      this.max_total_family = this.filterOptions.max_total_family;
      this.status = this.filterOptions.status;

      if (this.filterOptions.family) {
        this.familyQuery = JSON.parse(this.filterOptions.family).query;
      } else this.familyQuery = [];

      if (this.filterOptions.checked_in) {
        this.checkedInQuery = JSON.parse(this.filterOptions.checked_in).query;
      } else this.checkedInQuery = [];

      this.onSearchFamily();
    },
    onSendMessage() {
      this.messageDialog = true;
    },
    onQuickCheckedIn() {
      this.quickCheckedInDialog = true;
    },
    onClientMap() {
      this.$router.push({ name: "clientMap" });
    },
    async onDownloadExcel() {
      const schema = [
        {
          column: "Client Id",
          type: Number,
          align: "left",
          alignVertical: "top",
          value: (client) => client.client_id,
        },
        {
          column: "First Name",
          type: String,
          align: "left",
          alignVertical: "top",
          width: 15,
          value: (client) => client.first_name,
        },
        {
          column: "Last Name",
          type: String,
          align: "left",
          alignVertical: "top",
          width: 15,
          value: (client) => client.last_name,
        },
        {
          column: "Email",
          type: String,
          align: "left",
          alignVertical: "top",
          width: 20,
          value: (client) => client.email,
        },
        {
          column: "Phone",
          type: String,
          align: "left",
          alignVertical: "top",
          width: 18,
          value: (client) => this.formatPhoneNumber(client.phone),
        },
        {
          column: "Eligible",
          type: String,
          align: "left",
          alignVertical: "top",
          wrap: true,
          width: 15,
          value: (client) =>
            client.rest_balance
              ? Object.entries(client.rest_balance)
                  .map(([key, value]) => `${key}: ${value}`)
                  .join("\n")
              : null,
        },
        {
          column: "Address",
          type: String,
          align: "left",
          alignVertical: "top",
          width: 20,
          value: (client) => client.address,
        },
        {
          column: "Address 2",
          type: String,
          align: "left",
          alignVertical: "top",
          width: 20,
          value: (client) => client.address2,
        },
        {
          column: "City",
          type: String,
          align: "left",
          alignVertical: "top",
          width: 15,
          value: (client) => client.city,
        },
        {
          column: "Intake",
          type: String,
          wrap: true,
          width: 80,
          align: "left",
          alignVertical: "top",
          value: (client) => client.intake,
        },
        {
          column: "Intake Date",
          type: Date,
          format: "mm/dd/yyyy",
          align: "left",
          alignVertical: "top",
          value: (client) => new Date(client.intake_date),
        },
      ];
      let objects = [];

      this.downloadingExcel = true;
      try {
        var key = this.onGetKey();
        this.search = key;
        var params = {};
        if (key != "{}") {
          params = {
            ...JSON.parse(key),
          };
        }
        var res = await this.getExcelJsonData(params);
        if (res.success) {
          objects = res.res;
          console.log(objects);
          await writeXlsxFile(objects, {
            schema, // (optional) column widths, etc.
            fileName: "HC Clients.xlsx",
            sheet: new Date()
              .toLocaleDateString("en-US", {
                month: "2-digit",
                day: "2-digit",
                year: "2-digit",
              })
              .replace(/\//g, ""),
          });
          this.downloadingExcel = false;
        } else {
          this.showErrorMessage(res.message);
          this.downloadingExcel = false;
        }
      } catch (e) {
        this.showErrorMessage(e.message);
        this.downloadingExcel = false;
      }
    },
  },
  created() {
    this.loading = true;
    this.fetchAllClients()
      .then(() => {
        this.loading = false;
        this.selected = -1;
        this.onSearchFamily();

        // resume previous status (page, scroll position, selected client)
        setTimeout(() => {
          if (this.page != this.$route.query.page)
            this.page = this.$route.query.page
              ? parseInt(this.$route.query.page)
              : 1;

          if (
            this.$route.query.scroll &&
            this.offsetTop != this.$route.query.scroll
          ) {
            window.scrollTo(0, parseFloat(this.$route.query.scroll));
          } else {
            console.log(this.offsetTop);
          }

          this.selected = this.$route.query.selected
            ? parseInt(this.$route.query.selected)
            : -1;
        }, 1000);
      })
      .catch((error) => {
        this.loading = false;
        if (error.response != undefined && error.response.status === 401) {
          this.tokenDialog = true;
        } else {
          console.log(error);
        }
      });
    this.filterUpdateWithRoute();

    window.addEventListener("scroll", this.onScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },
  watch: {
    selected(newValue) {
      if (newValue != -1 && this.clients.length > newValue) {
        this.profile = this.clients[newValue];
        if (this.profile.balance.length != 0) {
          this.profile.balance = this.profile.balance.map((b) => {
            return { ...b, editing: false };
          });
        }
      }
      if (newValue != this.$route.query.selected) {
        this.$router.replace({
          query: { ...this.$route.query, selected: newValue },
        });
      }
    },
    keyword(newValue) {
      this.search = newValue;
      if (newValue) {
        this.onSearchFamily();
      }
    },
    allClients(newValue) {
      this.clients = [...newValue];
    },
    isNew(newValue) {
      if (
        (this.$route.query.new == "true" || this.$route.query.new == true) !=
        newValue
      ) {
        this.$router.replace({
          query: { ...this.$route.query, new: newValue ? "true" : "false" },
        });
      }
    },
    expanded(newValue) {
      for (var i = 0; i < newValue.length; i++) {
        if (!this.familyData[newValue[i].client_id]) {
          this.getFamilyData(newValue[i].client_id);
        }
      }
    },
    filterOptions(newValue) {
      this.search = this.onGetKey();
      if (
        (newValue || Object.keys(newValue).length) &&
        Object.getPrototypeOf(newValue) === Object.prototype
      ) {
        if (!this.$route.query) {
          this.$router.replace({
            query: { ...newValue },
          });
        } else {
          newValue = { ...this.$route.query, ...newValue };
          if (JSON.stringify(newValue) != JSON.stringify(this.$route.query)) {
            this.$router.replace({
              query: { ...newValue },
            });
          }
        }
      } else if (
        this.$router.query &&
        Object.keys(this.$router.query).length === 0 &&
        Object.getPrototypeOf(this.$router.query) === Object.prototype
      ) {
        this.$router.replace({
          query: {},
        });
      }
    },
    "$route.query": function() {
      this.filterUpdateWithRoute();
    },
    page: function(newValue) {
      if (newValue != this.$route.query.page) {
        this.$router.replace({
          query: { ...this.$route.query, page: newValue },
        });
      }
    },
    offsetTop(newValue) {
      if (newValue != this.$route.query.scroll)
        this.$router.replace({
          query: { ...this.$route.query, scroll: newValue },
        });
    },
  },
};
</script>
<style scoped>
.filter-title {
  font-family: "Poppins-SemiBold";
  color: #472583;
  margin-bottom: 5px;
}
.filteredClass {
  background: lightyellow;
  width: fit-content;
}
.client-list-body {
  width: 100%;
  min-width: 200px;
  margin-left: 60px;
  margin-right: 20px;
  align-items: center;
  margin-bottom: 30px;
}
.item_title {
  color: #6e3dc6;
  font-family: "Poppins-Bold";
  font-size: 25px;
  padding-left: 5px;
  padding-top: 10px;
}
.item_description {
  color: #482684;
  /* font-family: 'Poppins-Bold'; */
  font-size: 18px;
  padding-left: 7px;
}
.client_title {
  color: #747474;
  font-family: "Poppins-SemiBold";
  font-size: 16px;
}
.filter-chip-section >>> .v-chip {
  height: auto;
  padding-top: 5px;
  padding-bottom: 5px;
}

.filter-chip-section >>> .v-chip__content {
  white-space: initial;
}

.users_image_section {
  padding-bottom: 20px;
  padding-top: 30px;
  padding-left: 40px;
  justify-content: center;
  max-width: 200px;
}
.edit_button {
  position: absolute;
  bottom: 0px;
  right: 0px;
}
.profile {
  overflow: visible;
}
.request_description_title {
  color: #7024c4;
  font-family: "Poppins-SemiBold";
  font-size: 15px;
}
.request_description_item {
  color: #482684;
  font-family: "Poppins-SemiBold";
  font-size: 12px;
}
.client-data-table >>> table {
  position: relative;
}
.client-data-table >>> th {
  position: sticky;
  top: 120px;
  background: white;
  z-index: 10;
}
.client-data-table >>> .v-data-table__wrapper {
  overflow: inherit;
}
/* .v-text-field >>> input {
    font-size: 10px;
  } */
.v-text-field >>> .v-text-field__details {
  display: none;
}

/* Track */
.right-section::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 3px;
}
/* Handle */
.right-section::-webkit-scrollbar-thumb {
  background: rgba(240, 160, 0, 0.3);
  border-radius: 3px;
}

/* Handle on hover */
.right-section::-webkit-scrollbar-thumb:hover {
  background: #f0a000;
}
.v-select >>> input {
  display: none !important;
  background: red;
}
.v-text-field >>> input#map::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: transparent;
}
.v-text-field >>> input#map::-moz-placeholder {
  /* Firefox 19+ */
  color: transparent;
}
.v-text-field >>> input#map:-ms-input-placeholder {
  /* IE 10+ */
  color: transparent;
}
.v-text-field >>> input#map:-moz-placeholder {
  /* Firefox 18- */
  color: transparent;
}
.v-text-field >>> input#map::placeholder {
  color: transparent;
}
.v-data-table >>> .selected {
  background-color: #fff4e0 !important;
}
</style>
